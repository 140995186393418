import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import styles from "./footer-styles";

import Box from "@mui/material/Container";
import FooterLogoIcon from "../svgicons/FooterLogoIcon";
import FooterOTPIcon from "../svgicons/FooterOTPIcon";

function Footer() {
	return (
		<Container sx={{ ...styles.holder }}>
			<Box sx={{ ...styles.content }}>
				<Box sx={{ ...styles.content_left }}>
					<div style={styles.logo_holder}>
						<FooterLogoIcon />
					</div>
					<Typography variant="span">Част от:</Typography>
					<div style={styles.otp_holder}>
						<FooterOTPIcon />
					</div>
				</Box>
				<Box sx={{ ...styles.content_right }}>© Copyright 2025. OTP Group</Box>
			</Box>
		</Container>
	);
}

export default Footer;
